import { modalAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(modalAnatomy.keys);

/* 
Anatomy of Modal Component:
https://chakra-ui.com/docs/components/modal/theming */

// styles for mulitpart component
const baseStyle = definePartsStyle({
	dialog: {
		bg: "#000",
	},
	dialogContainer: {
		bg: "#000",
	},
	overlay: {
		bg: "#000",
	},
	header: {
		bg: "#F00",
	},
	footer: {},
	body: {
		bg: "pink",
	},
	closeButton: {},
});

// multi part sizes
const sizes = {};

// Variant styles per part
const variants = {};

// default props for entire modal
const defaultProps = {
	isCentered: true,
	size: "xl",
};

const multiStyleConfig = defineMultiStyleConfig({ baseStyle, variants, sizes });

const ModalConfig = multiStyleConfig; // { defaultProps, multiStyleConfig }

export default ModalConfig;
