import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { tailwindConstants } from "tailwind-config";

const themeColor = tailwindConstants.theme.extend.colors;
const color = tailwindConstants.theme.colors;
const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(switchAnatomy.keys);

/* 
Anatomy of Switch Component:
https://chakra-ui.com/docs/components/switch/theming */

const baseStyle = definePartsStyle({
	container: {},
	thumb: {},
	track: {
		bg: color.gray[400],
		_checked: {
			bg: color.green[700],
		},
	},
});

const sizes = {};
const variants = {};

const multiStyleConfig = defineMultiStyleConfig({ baseStyle, variants, sizes });

const SwitchConfig = multiStyleConfig;

export default SwitchConfig;
