import { drawerAnatomy } from "@chakra-ui/anatomy";
import { DrawerProps, createMultiStyleConfigHelpers } from "@chakra-ui/react";
const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(drawerAnatomy.keys);

/* 
Anatomy of Drawer Component:
https://chakra-ui.com/docs/components/modal/theming */

const baseStyle = definePartsStyle({
	dialog: {
		pb: 0,
	},
	dialogContainer: {},
	header: {
		position: "relative",
		mb: 0,
		pt: 0,
		pl: 0,
		pr: 0,
		pb: 6,
		display: "flex",
		fontSize: "3xl",
	},
	overlay: {
		bg: "gray.400",
	},
	footer: {},
	body: {
		p: 0,
	},
	closeButton: {
		position: "relative",
		aspectRatio: "1/1",
		display: "flex",
		flexBasis: "column",
		w: "3rem",
		h: "3rem",
		top: "unset",
		right: "unset",
		left: "unset",
		alignItems: "center",
		justifyItems: "center",
	},
});

const sizes = {};
const variants = {};

const DrawerConfig = defineMultiStyleConfig({ baseStyle, variants, sizes });

export default DrawerConfig;
