import { ComponentStyleConfig } from "@chakra-ui/react";
import { tailwindConstants } from "tailwind-config";

const themeColor = tailwindConstants.theme.extend.colors;
const color = tailwindConstants.theme.colors;

const InputConfig: ComponentStyleConfig = {
	baseStyle: {
		field: {
			fontFamily: "Lato",
			display: "block",
			py: 2,
			bg: "#FFF",
			borderRadius: ".25rem",
			borderWidth: "2px",
			borderColor: color.gray[100],
			color: color.black,
			fontWeight: "semibold",
			fontSize: ".75rem",
			transition: "all 200ms ease-in",
			_placeholder: {
				color: "gray.300",
			},

			_focus: {
				borderColor: themeColor.dark + " !important",
				boxShadow: tailwindConstants.theme.extend.boxShadow.default,
			},
			_disabled: {
				bg: color.gray[50],
				borderColor: color.gray[100],
				color: color.gray[500],
				_placeholder: {
					color: color.gray[300],
				},
			},
			_readOnly: {
				bg: color.gray[50],
				borderColor: color.gray[50],
				color: color.gray[400],
				_placeholder: {
					color: color.gray[300],
				},
			},
			_invalid: {
				color: color.red[500],
				borderColor: themeColor.error,
				_focus: {
					borderColor: themeColor.error,
					boxShadow: tailwindConstants.theme.extend.boxShadow.error,
				},
			},
		},
	},
	sizes: {
		sm: {
			field: {
				fontSize: "xs",
				px: "2",
				py: "1",

				borderRadius: ".25rem",
			},
		},
		md: {
			field: {
				fontSize: "sm",
				px: "3",
				py: "2",
			},
		},
		lg: {
			field: {
				fontSize: "md",
				px: "4",
				py: "3",
			},
		},
	},

	variants: {
		default: {
			field: {
				// see baseStyles
				_invalid: {
					color: themeColor.error,
					borderColor: themeColor.error,
					_focus: {
						borderColor: themeColor.error,
						boxShadow: tailwindConstants.theme.extend.boxShadow.error,
					},
				},
			},
		},
		error: {
			field: {
				color: themeColor.error,
				borderColor: themeColor.error,
				_focus: {
					borderColor: themeColor.error,
					boxShadow: tailwindConstants.theme.extend.boxShadow.error,
				},
			},
		},
		success: {
			field: {
				color: themeColor.success,
				borderColor: themeColor.success,
				_focus: {
					borderColor: themeColor.success,
					boxShadow: tailwindConstants.theme.extend.boxShadow.success,
				},
			},
		},
		warning: {
			field: {
				color: color.yellow[800],
				borderColor: themeColor.warning,
				_focus: {
					borderColor: color.yellow[800],
					boxShadow: tailwindConstants.theme.extend.boxShadow.warning,
				},
			},
		},
	},

	defaultProps: {
		size: "md",
		variant: "default",
	},
};

export default InputConfig;
