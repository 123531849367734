import { alertAnatomy } from "@chakra-ui/anatomy";
import { AlertProps, createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { tailwindConstants } from "tailwind-config";

const colors = tailwindConstants.theme.colors;

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props: AlertProps) => {
	const { status } = props;

	const successBase = status === "success" && {
		container: {
			background: colors.green[50],
			color: colors.green[500],
		},
	};

	const warningBase = status === "warning" && {
		container: {
			background: colors.yellow[50],
			color: "black",
		},
	};

	const errorBase = status === "error" && {
		container: {
			background: colors.red[50],
			color: colors.red[500],
		},
	};

	const infoBase = status === "info" && {
		container: {
			background: colors.blue[50],
			color: "black",
		},
	};

	return {
		...successBase,
		...warningBase,
		...errorBase,
		...infoBase,
	};
});

const alertConfig = defineMultiStyleConfig({ baseStyle });

export default alertConfig;
