import { ComponentStyleConfig } from "@chakra-ui/react";
import { tailwindConstants } from "tailwind-config";

const themeColor = tailwindConstants.theme.extend.colors;
const color = tailwindConstants.theme.colors;

const skeletonConfig: ComponentStyleConfig = {
	baseStyle: {},

	variants: {
		dark: {
			color: themeColor.dark,
		},
		medium: {
			color: themeColor.medium,
		},
		light: {
			color: themeColor.light,
		},
	},

	defaultProps: {
		startColor: color.gray["100"],
		endColor: themeColor.light,
		fadeDuration: 1,
		speed: 1,
	},
};

export default skeletonConfig;
