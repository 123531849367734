import { defineStyleConfig } from "@chakra-ui/react";
import { tailwindConstants } from "tailwind-config";

const themeColor = tailwindConstants.theme.extend.colors;
const color = tailwindConstants.theme.colors;

const BadgeConfig = defineStyleConfig({
	baseStyle: {
		borderRadius: "100%",
		color: color.black,
		borderColor: themeColor.dark,
		border: "none",
		text: themeColor.dark,
		fontWeight: "semibold",
		transition: "all 200ms ease-in",
	},
	variants: {
		default: {
			bg: color.white,
			color: color.black,
			_hover: { bg: themeColor.dark, color: themeColor.light },
		},
		primary: {
			bg: themeColor.primary,
			color: color.white,
			_hover: { bg: color.green[600], borderColor: color.green[600] },
		},
		secondary: {
			bg: themeColor.secondary,
			color: color.white,
			_hover: { bg: color.blue[600], borderColor: color.blue[600] },
		},
		info: {
			bg: themeColor.info,
			color: color.white,
			_hover: { bg: color.blue[400], borderColor: color.blue[400] },
		},
		success: {
			bg: themeColor.success,
			color: color.white,
			_hover: { bg: color.green[700], borderColor: color.green[700] },
		},
		warning: {
			bg: themeColor.warning,
			color: color.black,
			_hover: { bg: color.yellow[600], borderColor: color.yellow[600] },
		},
		error: {
			bg: themeColor.error,
			color: color.white,
			_hover: { bg: color.red[600], borderColor: color.red[600] },
		},
		light: {
			bg: themeColor.light,
			color: color.black,
			_hover: { bg: color.gray[100] },
		},
		dark: {
			bg: themeColor.dark,
			color: color.white,
			_hover: { bg: color.gray[400], borderColor: color.gray["400"] },
		},
		link: {
			color: themeColor.info,
			bg: "transparent",
			_hover: { color: color.blue[400], bg: "transparent" },
		},
		pill: {
			color: "inherit",
			bg: "inherit",
			textTransform: "none",
			rounded: "full",
			px: 2,
			py: 1,
		},
	},
});

export default BadgeConfig;
