import { tailwindConstants } from "tailwind-config";

import { extendTheme } from "@chakra-ui/react";

import componentConfig from "./componentConfig";

const theme = extendTheme({
	fonts: {
		heading: "Lato, sans-serif",
		body: "Lato, sans-serif",
	},
	components: componentConfig,
	colors: {
		...tailwindConstants.theme.extend.colors,
		...tailwindConstants.theme.colors,
	},
});

export default theme;
