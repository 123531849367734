import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	DrawerProps,
	Flex,
	HStack,
	Icon,
	IconButton,
	Show,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowsExpandIcon, XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React from "react";
import useNestedDrawers from "../store/useNestedDrawers";
import { DrawerIds } from "../types";

interface INestedDrawer {
	title?: React.ReactNode;
	isOpen?: boolean;
	position: DrawerIds;
	closeDrawer?: () => void;
	size: DrawerProps["size"];
	children?: React.ReactNode;
}

const NestedDrawer: React.FC<INestedDrawer> = ({ position, size = "xl" }) => {
	const {
		toggleDrawer,
		drawerContent,
		drawerDrawerOpenStatus,
		drawerMaximizedStatus,
		setDrawerIsMaximized,
	} = useNestedDrawers();

	const [offsetClass, setOffsetClass] = React.useState<string>("");
	const [overlayOpacity, setOverlayOpacity] =
		React.useState<string>("opacity-30");

	React.useEffect(() => {
		const openDrawers = Object.values(drawerDrawerOpenStatus).filter(
			(drawer) => {
				return !!drawer;
			},
		)?.length;
		let offset: string;
		if (position + 1 === openDrawers) offset = "mr-0";
		if (position + 2 === openDrawers) offset = "mr-8 lg:mr-20";
		if (position + 3 === openDrawers) offset = "mr-12 lg:mr-40";

		if (position + 1 === openDrawers) {
			setOverlayOpacity("opacity-30");
		} else setOverlayOpacity("opacity-0");

		setOffsetClass(offset);
	}, [drawerContent?.[position], drawerDrawerOpenStatus?.[position]]);

	const isMaximized = drawerMaximizedStatus?.[position];

	const toggleIsMaximized = () => setDrawerIsMaximized(position, !isMaximized);

	const handleDrawerClose = () => {
		toggleDrawer(position);
		setTimeout(() => {
			setDrawerIsMaximized(position, false);
		}, 500);
	};

	return React.useMemo(
		() => (
			<>
				<Drawer
					blockScrollOnMount={false}
					isOpen={drawerDrawerOpenStatus[position]}
					placement="right"
					onClose={handleDrawerClose}
					size={isMaximized ? "full" : size}
					closeOnOverlayClick={!drawerContent?.[position]?.ignoreOverlay}
				>
					<DrawerOverlay
						zIndex={position > 0 ? 1400 : 1399}
						className={classNames(overlayOpacity)}
					/>
					<DrawerContent
						className={classNames(
							"p-4 pb-0 transition-[margin] duration-200 ease-linear md:p-8 md:pb-0",
							offsetClass,
						)}
					>
						<DrawerHeader className="flex-col gap-4 pb-0 lg:flex-row lg:gap-6 items-start">
							<DrawerCloseButton>
								{position > 0 ? (
									<Icon as={ArrowLeftIcon} boxSize={6} />
								) : (
									<Icon as={XIcon} boxSize={6} />
								)}
							</DrawerCloseButton>
							<Box w="full">{drawerContent?.[position]?.title}</Box>
							<Show above="sm">
								<IconButton
									p="3"
									border="none"
									aria-label="expand drawer"
									icon={<Icon as={ArrowsExpandIcon} boxSize={6} />}
									_hover={{ bg: "gray.50" }}
									onClick={toggleIsMaximized}
								/>
							</Show>
						</DrawerHeader>
						<DrawerBody className="scrollbar-hide">
							{drawerContent?.[position]?.content}
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</>
		),
		[
			drawerContent?.[position],
			drawerDrawerOpenStatus?.[position],
			offsetClass,
			isMaximized,
		],
	);
};

export default NestedDrawer;
