"use client";
import { Portal } from "@chakra-ui/react";
import React from "react";
import NestedDrawer from "./NestedDrawer";

const BLXNestedDrawers: React.FC = () => {
	return React.useMemo(
		() => (
			<Portal>
				<NestedDrawer size="xl" position={0} />
				<NestedDrawer size="lg" position={1} />
				<NestedDrawer size="md" position={2} />
			</Portal>
		),
		[],
	);
};

export default BLXNestedDrawers;
